import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import NameInput from 'components/NameInput';
import QuestionField from 'components/QuestionField';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import useAboutYouQuestions from 'forms/AboutYouForm/questions';
import { Heading } from 'forms/SectionHeading/styles';
import { PageTitle, trackFormTextFieldFocus } from 'helpers/eventTracking';
import { useCustomerDetails } from 'state/formData/customerDetails';
import {
  ContactDetailsSectionContainer,
  StyledConfirmationBox,
  StyledFlexibleWidthDivider,
} from './styles';

type YourContactDetailsSectionProps = {
  getError: FieldFunction<FormDetails, string | undefined>;
  showValidation: FieldFunction<FormDetails, void>;
  sectionId: string;
};

export type Detail = {
  label: string;
  value: string;
  childAfter?: React.ReactNode;
};

type CsYourContactDetails = {
  csPetCheckYourDetailsDetailsSection: {
    your_contact_details: {
      heading: string;
      explanatory_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      your_contact_details {
        heading
        explanatory_text
      }
    }
  }
`;

const CheckContactDetailsForm: React.FC<YourContactDetailsSectionProps> = ({
  getError,
  showValidation,
  sectionId,
}) => {
  const {
    csPetCheckYourDetailsDetailsSection: { your_contact_details },
  } = useStaticQuery<CsYourContactDetails>(query);

  const questions = useAboutYouQuestions();
  const [customerDetails, updateCustomerDetails] = useCustomerDetails();

  return (
    <ContactDetailsSectionContainer aria-labelledby={sectionId}>
      <Heading>{your_contact_details.heading}</Heading>
      <StyledFlexibleWidthDivider aria-hidden />
      <Grid alignLeft>
        <GridItem desktop={7} tabletLandscape={7} tabletPortrait={6}>
          <StyledConfirmationBox
            pageTitle={PageTitle.CheckYourDetails}
            html={your_contact_details.explanatory_text}
          />
        </GridItem>
        <GridItem>
          <QuestionField
            gridItemProps={{ desktop: 7, tabletLandscape: 7, tabletPortrait: 6 }}
            question={questions.customerEmail}
            errorText={getError('customerEmail')}>
            <TextInput
              id="customerEmail"
              placeholder={questions.customerEmail.placeholder}
              maxLength={254}
              value={customerDetails.customerEmail}
              onChange={(e) => {
                updateCustomerDetails({
                  customerEmail: e.target.value.replaceAll(' ', ''),
                });
              }}
              onBlur={() => showValidation('customerEmail')}
              onFocus={trackFormTextFieldFocus('Customer email')}
              inputMode="email"
            />
          </QuestionField>
        </GridItem>
        <GridItem>
          <QuestionField
            gridItemProps={{ desktop: 7, tabletLandscape: 7, tabletPortrait: 6 }}
            question={questions.customerTelephone}
            errorText={getError('customerTelephone')}>
            <NameInput
              id="customerTelephone"
              placeholder={questions.customerTelephone.placeholder}
              maxLength={11}
              value={customerDetails.customerTelephone}
              onChange={(e) => {
                updateCustomerDetails({
                  customerTelephone: e.target.value,
                });
              }}
              numbersOnly
              onBlur={() => showValidation('customerTelephone')}
              onFocus={trackFormTextFieldFocus('Customer telephone')}
              inputMode="tel"
            />
          </QuestionField>
        </GridItem>
      </Grid>
    </ContactDetailsSectionContainer>
  );
};
export default CheckContactDetailsForm;
