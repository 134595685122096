import EditButton from '@rsa-digital/evo-shared-components/components/EditButton';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css } from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';
import AdditionalFormSectionBanner from 'forms/AdditionalFormSectionBanner';

export const DetailGroupSectionContainer = styled.section`
  background-color: ${colors.core02};
  padding: ${spacing(3)} ${spacing(2)};
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(6)} ${spacing(8)};
  `}
`;

export const UnorderedListWithoutBullets = styled.ul`
  text-align: left;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ListItemWithMargins = styled.li<{ withDividers: boolean }>`
  ${({ withDividers }) =>
    withDividers
      ? css`
      padding: ${spacing(2)} 0;

      ${mediaQuery.tabletLandscape`
        padding: ${spacing(3)} 0;
      `}

      border-top: solid 1px ${colors.neutral04};`
      : css`
          margin: ${spacing(2)} 0;

          ${mediaQuery.tabletLandscape`
            margin: ${spacing(3)} 0;
          `}
        `}
`;

export const ParagraphLargeSpan = styled.span`
  ${fonts.paragraphLarge};
`;

export const DetailLabel = styled(ParagraphLargeSpan)`
  display: block;

  ${mediaQuery.tabletPortrait`
    display: inline;
  `}
`;

export const BoldParagraphLargeSpan = styled.span`
  ${fonts.paragraphLarge};
  & {
    font-weight: bold;
  }
`;

export const EditButtonContainer = styled.div`
  display: flex;
  justify-content: right;
`;

export const EditButtonWithMargins = styled(EditButton)`
  margin: ${spacing(3)} 0 0;
  margin-left: auto;

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(4)};
  `}
`;

export const PositionedQuoteReferenceWrapper = styled.div`
  position: relative;
  width: 100%;

  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    top: ${spacing(6)};
    margin-bottom: 0;
    text-align: right;
  `}
`;

export const LeftPositionedQuoteReferenceWrapper = styled.div`
  width: 100%;
  margin-bottom: ${spacing(3)};
`;

export const LargeRichTextWithModalWithMargin = styled(RichTextWithModal)`
  p {
    ${fonts.paragraphLarge}
  }

  margin: ${spacing(3)} 0;
`;

export const StyledAdditionalFormSectionBanner = styled(AdditionalFormSectionBanner)`
  margin: 0;
  padding: 0;
`;
