import EditButton from '@rsa-digital/evo-shared-components/components/EditButton';
import {
  unorderedListItemStyling,
  unorderedListStyling,
} from '@rsa-digital/evo-shared-components/components/RichText/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing, {
  spacingWithBorder,
} from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import SectionHeading from 'forms/SectionHeading';

export const StyledSectionHeading = styled(SectionHeading)`
  margin: 0 0 ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(6)};
  `}

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(4)} 0 ${spacing(3)};
  `}
`;

export const Subheading = styled.div`
  ${fonts.paragraphLarge}
  & {
    font-weight: bold;
  }
`;

export const AggregatorList = styled.ul`
  ${unorderedListStyling}

  ${fonts.paragraphLarge}
  & {
    margin: 0;
  }
`;

export const AggregatorListItem = styled.li`
  ${unorderedListItemStyling}
`;

export const AssumptionsQuestionField = styled(QuestionField)`
  margin-bottom: ${spacingWithBorder(6, 1)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacingWithBorder(2, 1)};
  `}
`;

export const EditButtonWithMargins = styled(EditButton)`
  margin: ${spacing(2)} 0;

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}
`;
