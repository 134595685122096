import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import Declaration from 'components/Declaration';
import RichTextWithModal from 'components/RichTextWithModal';

export const RichTextWithModalAndMarginBottom = styled(RichTextWithModal)`
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(6)};
  `}
`;

export const RichTextWithModalAndMargin = styled(RichTextWithModal)`
  margin: ${spacing(2)} ${spacing(2)} ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin: ${spacing(6)} 0 ${spacing(4)} ${spacing(8)};
  `}
`;

export const DeclarationWithBottomMargin = styled(Declaration)`
  &:not(:last-child) {
    margin-bottom: ${spacing(4)};
  }
`;
