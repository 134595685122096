import { graphql, useStaticQuery } from 'gatsby';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import getCurrentVetFeeLimit from 'helpers/useCurrentVetFeeLimit';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsCoverLevelSectionProps = {
  csPetCheckYourDetailsDetailsSection: {
    your_cover_level: {
      cover_type: string;
      vet_fees_limit: string;
      vet_limits_products_texts: {
        standard: string;
        extra: string;
        premier: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      your_cover_level {
        cover_type
        vet_fees_limit
        vet_limits_products_texts {
          standard
          extra
          premier
        }
      }
    }
  }
`;

const useCoverLevelSection = (): Detail[] => {
  const {
    csPetCheckYourDetailsDetailsSection: {
      your_cover_level: { cover_type, vet_fees_limit, vet_limits_products_texts },
    },
  } = useStaticQuery<CsCoverLevelSectionProps>(query);
  const quote = useCurrentQuote();

  const product = getProductFromQuoteOptions(quote.quoteOptions);

  const feeLimitReplacer = (feeLimit: string, csString: string): string =>
    csString.replace(/({{feeLimit}})/g, feeLimit);

  const getVetFeeLimitText = (): string => {
    const feeLimit = getCurrentVetFeeLimit(quote.quoteOptions);

    switch (product) {
      case Product.Standard: {
        return feeLimitReplacer(feeLimit, vet_limits_products_texts.standard);
      }
      case Product.Extra: {
        return feeLimitReplacer(feeLimit, vet_limits_products_texts.extra);
      }
      case Product.Premier: {
        return feeLimitReplacer(feeLimit, vet_limits_products_texts.premier);
      }
      default: {
        return '';
      }
    }
  };

  const getCoverTypeName = (): string => {
    switch (product) {
      case Product.Standard: {
        return 'Standard';
      }
      case Product.Extra: {
        return 'Extra';
      }
      case Product.Premier: {
        return 'Premier';
      }
      default: {
        return '';
      }
    }
  };

  return [
    createDetail(cover_type, getCoverTypeName()),
    createDetail(vet_fees_limit, getVetFeeLimitText()),
  ];
};

export default useCoverLevelSection;
