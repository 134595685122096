import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { ComponentProps } from '@rsa-digital/evo-shared-components/helpers/componentProps';
import {
  dateToDateValue,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { useFirstEligibleStartDateFromToday } from 'businessLogic/petAge';
import React, { useEffect, useReducer, useState } from 'react';
import { areDateValuesEqual, formatLongDateWithOrdinalSuffix } from 'helpers/dateHelpers';
import { PageTitle, trackLinkClick } from 'helpers/eventTracking';
import {
  combinePetNames,
  petNameReplacer,
} from 'helpers/placeholders/dynamicPetNameHelpers';
import { usePetsDetails } from 'state/formData/petsDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import {
  StyledCoverStartDateQuestion,
  StyledExplanatoryText,
  StyledSectonHeader,
  StyledStartCoverDatePanel,
} from './styles';
import useAboutYourPolicyQuestions from '../../forms/AboutYourPolicyForm/questions';

type CoverStartDateProps = {
  pageTitle: PageTitle;
} & ComponentProps;

const CoverStartDate: React.FC<CoverStartDateProps> = ({ pageTitle }) => {
  const showCalendarByDefault = true;
  const currentPage = pageTitle;
  const [showCalendar, toggleCalendar] = useReducer(
    (state) => !state,
    !showCalendarByDefault
  );

  const [policyDetails] = usePolicyDetails();
  const [petsDetails] = usePetsDetails();
  const [minDate, isMinDateInFuture] = useFirstEligibleStartDateFromToday();

  const coverStartDateUtc = new Date(policyDetails.coverStartDate);

  const formattedCoverStartDate = formatLongDateWithOrdinalSuffix(
    coverStartDateUtc,
    false
  );
  const questions = useAboutYourPolicyQuestions();

  const petNames = petsDetails.map((pet) => pet.petName);
  const combinedPetNames = combinePetNames(petNames ?? []);

  const isStartDateToday = areDateValuesEqual(
    dateToDateValue(new Date()),
    dateToDateValue(parseDate(policyDetails.coverStartDate))
  );

  const startDateText = isStartDateToday
    ? petNameReplacer(
        combinedPetNames,
        questions.start_date_panel.your_cover_will_start_today_label
      )
    : petNameReplacer(
        combinedPetNames,
        questions.start_date_panel.your_cover_will_start_label
      );

  const [displayYoungPetMessage, setDisplayYoungPetMessage] = useState(false);
  const { questionText } = questions.coverStartDate;
  const explanatoryText = displayYoungPetMessage
    ? questions.coverStartDate.youngPetExplanatoryText
    : questions.coverStartDate.explanatoryText;

  useEffect(() => {
    setDisplayYoungPetMessage(isMinDateInFuture);
  }, [isMinDateInFuture]);

  return (
    <>
      <Grid alignLeft>
        <GridItem desktop={6} tabletLandscape={6}>
          {currentPage !== PageTitle.CheckYourDetails && (
            <StyledSectonHeader>{questionText}</StyledSectonHeader>
          )}
          <StyledExplanatoryText>{explanatoryText}</StyledExplanatoryText>
          <StyledStartCoverDatePanel
            id="cover-start-date"
            summaryText={startDateText}
            heading={formattedCoverStartDate}
            link={{
              onClick: () => {
                toggleCalendar();
                trackLinkClick(
                  PageTitle.CoverStartDate,
                  showCalendar
                    ? questions.start_date_panel.hide_calendar_text
                    : questions.start_date_panel.choose_a_different_start_date_text
                );
              },
              text: showCalendar
                ? questions.start_date_panel.hide_calendar_text
                : questions.start_date_panel.choose_a_different_start_date_text,
              screenreaderText: showCalendar
                ? questions.start_date_panel.hide_calendar_screenreader_text
                : questions.start_date_panel
                    .choose_a_different_start_date_screenreader_text,
            }}
          />
        </GridItem>
      </Grid>
      {showCalendar && (
        <StyledCoverStartDateQuestion
          hideQuestionText
          minDate={minDate}
          onChangeCallback={() => setDisplayYoungPetMessage(false)}
        />
      )}
    </>
  );
};

export default CoverStartDate;
