import Image from '@rsa-digital/evo-shared-components/components/Image';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { colors } from '@rsa-digital/evo-shared-components/theme/Tesco/colors';
import styled from 'styled-components';

export const ImageWithFixedHeight = styled(Image)`
  height: ${spacing(6)};
  width: auto;
  margin: 0;
`;

export const StyledSectionContainer = styled.section`
  background-color: ${colors.core02};
  padding: ${spacing(3)} ${spacing(2)};
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(6)} ${spacing(8)};
  `}
`;
