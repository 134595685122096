import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import ConfirmationBox from 'components/ConfirmationBox';

export const ContactDetailsSectionContainer = styled.section`
  background-color: ${colors.core02};
  padding: ${spacing(3)} ${spacing(2)} 0;
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(6)} ${spacing(8)} ${spacing(2)};
  `}
`;

export const StyledFlexibleWidthDivider = styled(FlexibleWidthDivider)`
  margin-bottom: ${spacing(3)};
`;

export const StyledConfirmationBox = styled(ConfirmationBox)`
  padding: ${spacing(2)} ${spacing(3)} ${spacing(3)} ${spacing(2)};
  margin-bottom: ${spacing(3)};
`;
