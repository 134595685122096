import { graphql, useStaticQuery } from 'gatsby';
import { formatLongDateWithOrdinalSuffix } from 'helpers/dateHelpers';
import { fullAddressToString, manualAddressToString } from 'helpers/formatAddress';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { useCustomerDetails } from 'state/formData/customerDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsAboutYouSectionProps = {
  csPetCheckYourDetailsDetailsSection: {
    about_you: {
      address: string;
      date_of_birth: string;
      email_address: string;
      first_name: string;
      last_name: string;
      telephone_number: string;
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      about_you {
        address
        date_of_birth
        email_address
        first_name
        last_name
        telephone_number
      }
    }
  }
`;

const useAboutYouSection = (): Detail[] => {
  const {
    csPetCheckYourDetailsDetailsSection: {
      about_you: { address, date_of_birth, first_name, last_name },
    },
  } = useStaticQuery<CsAboutYouSectionProps>(query);

  const quote = useCurrentQuote();
  const [customerDetails] = useCustomerDetails();
  const countiesRefData = useReferenceData('counties')?.counties ?? [];

  const customerInfo = quote.customerInfo ?? undefined;
  if (customerInfo === undefined) {
    return [];
  }

  const formattedAddress = customerDetails.address
    ? `${fullAddressToString(customerDetails.address)}, ${
        customerDetails.postcodeLookup.lookupKey
      }`
    : manualAddressToString(customerDetails, countiesRefData);

  return [
    createDetail(first_name, customerInfo.firstName),
    createDetail(last_name, customerInfo.lastName),
    createDetail(date_of_birth, formatLongDateWithOrdinalSuffix(customerInfo.dob)),
    createDetail(address, formattedAddress),
  ];
};

export default useAboutYouSection;
