import { graphql, useStaticQuery } from 'gatsby';
import { useTheme } from 'styled-components';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { usePolicyDocumentLink } from 'helpers/usePolicyDocumentLink';
import { DocumentsSectionProps, PdfLink } from './DocumentsSection';

type DocumentSectionContent = {
  csPetPolicyDocuments: {
    heading: string;
    summary: string;
    essential_information: PdfLink;
    policy_booklet: PdfLink;
  };
};

const query = graphql`
  query {
    csPetPolicyDocuments {
      heading
      summary
      essential_information {
        url
        text
      }
      policy_booklet {
        url
        text
      }
    }
  }
`;

export const useDocumentSectionProps = (): DocumentsSectionProps => {
  const {
    csPetPolicyDocuments: { heading, summary, essential_information, policy_booklet },
  } = useStaticQuery<DocumentSectionContent>(query);

  const theme = useTheme();
  const { quoteOptions } = useCurrentQuote();
  const productInformationLink = usePolicyDocumentLink(quoteOptions);

  return {
    theme,
    heading,
    summary,
    essentialInformation: essential_information,
    policyBooklet: policy_booklet,
    productInformationLink,
  };
};
