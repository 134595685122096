import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import { dateValueToISODateString } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { isQuoteOptionSelectionValid } from 'apiHelpers/quote/bundleCoverMapping';
import useGenerateQuote from 'apiHelpers/quote/useGenerateQuote';
import { graphql, navigate } from 'gatsby';
import React, { useMemo } from 'react';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import AggregatorAssumptionsSection from 'components/CheckYourDetails/AggregatorAssumptionsSection';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/useAssumptions';
import useAggregatorAssumptionsRules from 'components/CheckYourDetails/AggregatorAssumptionsSection/validation';
import DeclarationSection from 'components/CheckYourDetails/DeclarationSection';
import DetailsSection from 'components/CheckYourDetails/DetailsSection';
import { useCheckYourDetailsPdf } from 'components/CheckYourDetailsPdf/useCheckYourDetailsPdf';
import FormFooter from 'components/FormFooter';
import Layout from 'components/Layout';
import PricingOverviewPanel from 'components/PricingOverviewPanel';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import DiscountSection from 'components/QuoteSummary/SummaryOfYourNeeds/DiscountSection';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import { useContactDetailsRules } from 'forms/CheckContactDetailsForm/validation';
import useJointPolicyholderRules from 'forms/JointPolicyholderForm/validation';
import KeepingYouInformedForm from 'forms/KeepingYouInformedForm';
import useKeepingYouInformedRules from 'forms/KeepingYouInformedForm/validation';
import SectionHeading from 'forms/SectionHeading';
import { ProductId } from 'helpers/businessConstants';
import {
  displayClubcardDiscount,
  displayMultipetDiscount,
  displayOnlineDiscount,
  useValidPricePanelOffers,
} from 'helpers/discountHelpers';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import useDefaultErrorHandling from 'helpers/errorHandling';
import {
  PageTitle,
  trackFieldError,
  trackSwitchPaymentClick,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { TescoPageViewEventLevel } from 'helpers/eventTrackingForTesco';
import { scrollAndTrackError } from 'helpers/forms';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';
import { isQuoteFromAggs } from 'helpers/productHelpers';
import { isQuoteSummaryDetailsInvalid } from 'helpers/quoteAndBuyPageFlowHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import {
  PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
  storeData,
} from 'helpers/sessionStorageHelpers';
import useAggregatorBrandDetails from 'helpers/useAggregatorBrandDetails';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useDeclarations from 'helpers/useDeclarations';
import useLoadingState from 'helpers/useLoadingState';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { useJointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { usePolicyDetails } from 'state/formData/policyDetails';
import { initialQuoteOptions } from 'state/formData/quoteOptions';
import { CsAsset, CsHero } from 'types/contentStack';
import { ImageWithFixedHeight, StyledSectionContainer } from './styles';

const STEP = 4;

type CheckYourDetailsProps = {
  data: {
    csPetCheckYourDetails: {
      meta_title: string;
      hero: CsHero;
      next_button_text: string;
    };
    csPetAboutYouAndYourPet: {
      keeping_you_informed_section_heading: string;
    };
    csPetQuoteSummaryOfYourNeeds: {
      discounts: {
        online_discount_icon: CsAsset | null;
      };
    };
    csPetGlobalConfig: {
      loading_spinner: {
        email_spinner_text: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetCheckYourDetails {
      meta_title
      hero {
        heading
        subheading
      }
      next_button_text
    }
    csPetAboutYouAndYourPet {
      keeping_you_informed_section_heading
    }
    csPetQuoteSummaryOfYourNeeds {
      discounts {
        online_discount_icon {
          ...CsAsset
        }
      }
    }
    csPetGlobalConfig {
      loading_spinner {
        email_spinner_text
      }
    }
  }
`;

const CheckYourDetails: React.FC<CheckYourDetailsProps> = ({
  data: {
    csPetCheckYourDetails: { hero, next_button_text, meta_title },
    csPetAboutYouAndYourPet: { keeping_you_informed_section_heading },
    csPetQuoteSummaryOfYourNeeds: {
      discounts: { online_discount_icon },
    },
    csPetGlobalConfig: {
      loading_spinner: { email_spinner_text },
    },
  },
}) => {
  const aggregatorAssumptionsSectionId = 'aggregator-assumptions-section';
  const declarationSectionId = 'declaration-section';
  const keepingYouInformedSectionId = 'keeping-you-informed-section';

  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();
  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;
  const { isAnnualPayment } = quoteOptions;
  const isAggsQuote = isQuoteFromAggs(quote);
  const [customerDetails] = useCustomerDetails();
  const [policyDetails] = usePolicyDetails();
  const [jointPolicyholderDetails] = useJointPolicyholderDetails();
  const { withLoadingState } = useLoadingState();
  const defaultErrorHandling = useDefaultErrorHandling();
  if (quote.petInfos && isQuoteSummaryDetailsInvalid(quote.quoteOptions)) {
    navigate(quoteAndBuyRoutes.quoteSummary);
  }

  const paymentOnClick = (): void => {
    trackSwitchPaymentClick(
      PageTitle.QuoteSummary,
      isAnnualPayment ? 'monthly' : 'annually'
    );
    updateQuoteOptions({
      isAnnualPayment: !isAnnualPayment,
    });
  };

  const {
    declarations,
    updateDeclarationAgreement,
    declarationRules,
  } = useDeclarations();

  const aggregatorBrandDetails = useAggregatorBrandDetails(quote.productId);

  const aggregatorAssumptionRules = useAggregatorAssumptionsRules();
  const keepInformedRules = useKeepingYouInformedRules();
  const contactDetailsRules = useContactDetailsRules();

  const [assumptionsAgreement] = useAssumptionsAgreement();
  const jointPolicyHolderRules = useJointPolicyholderRules();

  const rules = {
    ...contactDetailsRules,
    ...declarationRules,
    ...(isAggsQuote ? aggregatorAssumptionRules : {}),
    ...(isAggsQuote ? keepInformedRules : {}),
    ...(jointPolicyholderDetails.includeJointPolicyholder ? jointPolicyHolderRules : {}),
  };

  const { getError, validateOnSubmit, showValidation } = useValidation(
    {
      ...customerDetails,
      declarations,
      ...assumptionsAgreement,
      keepInformed: policyDetails.keepInformed,
      ...jointPolicyholderDetails,
    },
    rules,
    trackFieldError
  );

  const assumptions = useAssumptions();

  /*  We only display the aggregator assumptions section if some
   *  assumptions exist and the assumptions have not yet been agreed
   *  to when the page is first rendered, so we must record the assumptionsAgreed value on render.
   */
  const assumptionsAgreedOnRender = useMemo(
    () => assumptionsAgreement.assumptionsAgreed,
    [assumptionsAgreement.assumptionsAgreed]
  );

  const {
    saveAndEmailQuote,
    savedQuoteConfirmationModal,
    isSaveInProgress,
  } = useSaveAndEmailQuote(PageTitle.CheckYourDetails);

  const { createQuote: requote, isLoading: isRequoting } = useGenerateQuote();

  const isPolicyModified =
    (policyDetails.keepInformed !== undefined &&
      quote?.policyInfo?.contactable !== null &&
      policyDetails.keepInformed !== quote?.policyInfo?.contactable) ||
    customerDetails.customerEmail !== quote.customerInfo?.email ||
    customerDetails.customerTelephone !== quote.customerInfo?.contactPhoneNumber ||
    policyDetails.coverStartDate !== quote.policyInfo?.coverStartDate ||
    jointPolicyholderDetails.includeJointPolicyholder !==
      quote.policyInfo?.includeJoinPolicyHolder ||
    (jointPolicyholderDetails.includeJointPolicyholder &&
      jointPolicyholderDetails.jointPolicyholderTitle !==
        quote.policyInfo?.joinPolicyHolder?.title) ||
    (jointPolicyholderDetails.includeJointPolicyholder &&
      jointPolicyholderDetails.jointPolicyholderFirstName !==
        quote.policyInfo?.joinPolicyHolder?.firstName) ||
    (jointPolicyholderDetails.includeJointPolicyholder &&
      jointPolicyholderDetails.jointPolicyholderLastName !==
        quote.policyInfo?.joinPolicyHolder?.lastName) ||
    (jointPolicyholderDetails.includeJointPolicyholder &&
      dateValueToISODateString(jointPolicyholderDetails.jointPolicyholderDob) !==
        quote.policyInfo?.joinPolicyHolder?.dob);

  const moveNext = async (): Promise<void> => {
    if (isPolicyModified) {
      await requote();
    }
    navigate(quoteAndBuyRoutes.payment, {
      state: { detailsValid: true },
    });
  };

  const showAssumptions = !!assumptions && !assumptionsAgreedOnRender;

  const generatePdf = useCheckYourDetailsPdf(
    quote,
    declarations,
    assumptionsAgreement,
    policyDetails.keepInformed,
    showAssumptions
  );

  const downloadPdf = async (): Promise<void> => {
    if (isPolicyModified) {
      try {
        await withLoadingState(requote);
        generatePdf();
      } catch (err) {
        const error: Error = err as Error;
        defaultErrorHandling(error);
      }
    }
  };

  const pricePanelOffers = useValidPricePanelOffers();
  const displayPricePanelOffers = !!pricePanelOffers && pricePanelOffers.length > 0;

  const shouldDisplayPricePanelDiscounts =
    displayClubcardDiscount(quote) ||
    displayMultipetDiscount(quote) ||
    displayOnlineDiscount(online_discount_icon) ||
    displayPricePanelOffers;

  usePageTracking(meta_title, !!quote.customerInfo);
  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();
  useTescoPageViewTracking(TescoPageViewEventLevel.extended);
  useCheckoutTracking(STEP, quote, true);
  return (
    <LoadQuoteWrapper>
      <Layout
        currentStep={QuoteAndBuyStep.CheckDetails}
        pageTitle={PageTitle.CheckYourDetails}
        heading={hero.heading}
        subheading={hero.subheading}
        metaTitle={meta_title}
        sessionExpiryOption={
          isQuoteOptionSelectionValid(quoteOptions)
            ? SessionExpiryOption.EMAIL_QUOTE_SESSION_EXPIRY
            : undefined
        }>
        {isRequoting && (
          <LoadingOverlayV2
            loadingMessage="Updating preferences, please wait"
            timeDuration={10}
          />
        )}
        {isSaveInProgress && (
          <LoadingOverlayV2 loadingMessage={email_spinner_text} timeDuration={10} />
        )}
        <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
          {quote.productId !== ProductId.DIRECT && (
            <StyledSectionContainer>
              {aggregatorBrandDetails?.logo && (
                <Grid data-cy="aggregator-logo" alignLeft>
                  <GridItem>
                    <ImageWithFixedHeight image={aggregatorBrandDetails.logo} />
                  </GridItem>
                </Grid>
              )}
              {showAssumptions && (
                <section aria-labelledby={aggregatorAssumptionsSectionId}>
                  <AggregatorAssumptionsSection
                    sectionHeadingId={aggregatorAssumptionsSectionId}
                    getError={getError}
                    showValidation={showValidation}
                  />
                </section>
              )}
            </StyledSectionContainer>
          )}
          <DetailsSection
            currentQuote={quote}
            getError={getError}
            showValidation={showValidation}
          />
          <section aria-label="Payment Overview">
            {shouldDisplayPricePanelDiscounts && (
              <DiscountSection data-cy="quoteSummaryDiscounts" />
            )}
            <PricingOverviewPanel
              data-cy="pricingOverviewPanel"
              pageTitle={PageTitle.CheckYourDetails}
              paymentOnClick={paymentOnClick}
            />
          </section>
          <section aria-labelledby={declarationSectionId}>
            <DeclarationSection
              sectionHeadingId={declarationSectionId}
              declarations={declarations}
              updateHasAgreed={(update: boolean, index: number) => {
                updateDeclarationAgreement(update, index);
                showValidation('declarations', ['hasAgreed', index]);
              }}
              getError={getError}
            />
          </section>
          {isAggsQuote && (
            <section aria-labelledby={keepingYouInformedSectionId}>
              <SectionHeading
                heading={keeping_you_informed_section_heading}
                id={keepingYouInformedSectionId}
              />
              <KeepingYouInformedForm formValidation={{ getError, showValidation }} />
            </section>
          )}
          <FormFooter
            backButton={{
              onClick: () => {
                trackTextButtonClick(PageTitle.CheckYourDetails, 'Back');
                navigate(quoteAndBuyRoutes.quoteSummary);
              },
            }}
            moveNextButton={{
              text: next_button_text,
              onClick: () => {
                storeData(
                  PAYMENT_MODE_PREVIOUSLY_SELECTED_KEY,
                  quote.quoteOptions.isAnnualPayment ? 'ANNUAL' : 'MONTHLY'
                );
                trackTextButtonClick(PageTitle.CheckYourDetails, 'Continue');
              },
            }}
            downloadButton={{
              onClick: downloadPdf,
            }}
            saveButton={{
              onClick: () => {
                if (isQuoteOptionSelectionValid(quoteOptions)) {
                  saveAndEmailQuote();
                }
              },
            }}
            pageTitle={PageTitle.CheckYourDetails}
          />
          {savedQuoteConfirmationModal}
        </form>
      </Layout>
    </LoadQuoteWrapper>
  );
};

export default CheckYourDetails;
