import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import React, { Fragment, MouseEventHandler } from 'react';
import { Heading } from 'forms/SectionHeading/styles';
import { PageTitle } from 'helpers/eventTracking';
import {
  BoldParagraphLargeSpan,
  DetailGroupSectionContainer,
  DetailLabel,
  EditButtonContainer,
  EditButtonWithMargins,
  LargeRichTextWithModalWithMargin,
  ListItemWithMargins,
  ParagraphLargeSpan,
  PositionedQuoteReferenceWrapper,
  UnorderedListWithoutBullets,
} from './styles';

type DetailGroupSectionProps = {
  quoteReference?: Detail;
  sectionHeading: string;
  sectionId: string;
  details: Detail[];
  editButtonLabel: string;
  editButtonScreenreaderText: string;
  editButtonOnClick: MouseEventHandler;
  piiOptions?: PiiOptions;
  // eslint-disable-next-line react/no-unused-prop-types
  isCoinsuranceQuote?: boolean;
  sectionDescription?: string;
  sectionDisclaimer?: string;
};

export type Detail = {
  label: string;
  value: string;
  childAfter?: React.ReactNode;
};

export type PiiOptions = {
  headingIsPii?: boolean;
  detailLabelsArePii?: boolean;
  detailValuesArePii?: boolean;
};

const DetailGroupSection: React.FC<DetailGroupSectionProps> = ({
  details,
  editButtonOnClick,
  editButtonLabel,
  editButtonScreenreaderText,
  quoteReference,
  sectionHeading,
  sectionId,
  piiOptions,
  sectionDescription,
  sectionDisclaimer,
}) => {
  return (
    <DetailGroupSectionContainer aria-labelledby={sectionId}>
      {quoteReference && (
        <PositionedQuoteReferenceWrapper>
          <ParagraphLargeSpan>{quoteReference.label} </ParagraphLargeSpan>
          <BoldParagraphLargeSpan>{quoteReference.value}</BoldParagraphLargeSpan>
        </PositionedQuoteReferenceWrapper>
      )}
      <Heading id={sectionId}>{sectionHeading}</Heading>
      {sectionDescription && (
        <>
          <FlexibleWidthDivider aria-hidden />
          <LargeRichTextWithModalWithMargin
            pageTitle={PageTitle.CheckYourDetails}
            html={sectionDescription}
          />
        </>
      )}

      <UnorderedListWithoutBullets>
        {details.map((detail) => (
          <Fragment key={detail.label}>
            <ListItemWithMargins withDividers={!sectionDescription && !sectionDisclaimer}>
              <DetailLabel data-pii-mask={piiOptions?.detailLabelsArePii}>
                {detail.label}
              </DetailLabel>
              <BoldParagraphLargeSpan data-pii-mask={piiOptions?.detailValuesArePii}>
                {detail.value}
              </BoldParagraphLargeSpan>
              {detail?.childAfter}
            </ListItemWithMargins>
          </Fragment>
        ))}
      </UnorderedListWithoutBullets>

      {sectionDisclaimer && (
        <LargeRichTextWithModalWithMargin
          pageTitle={PageTitle.CheckYourDetails}
          html={sectionDisclaimer}
        />
      )}
      <EditButtonContainer>
        <EditButtonWithMargins
          id={`${sectionId}-edit-button`}
          screenReaderText={editButtonScreenreaderText}
          onClick={editButtonOnClick}>
          {editButtonLabel}
        </EditButtonWithMargins>
      </EditButtonContainer>
    </DetailGroupSectionContainer>
  );
};

export default DetailGroupSection;
