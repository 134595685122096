import { getCoverStartDate } from 'businessLogic/getCoverStartDate';
import { isPet8YearsOrOlderOnDate } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { formatStringInPoundsWithSign } from 'helpers/poundsHelpers';
import { isCoinsuranceQuote } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { Detail } from './DetailGroupSection';
import { createDetail } from './useCreateDetail';

type CsExcessSectionProps = {
  csPetCheckYourDetailsDetailsSection: {
    excess: {
      fixed_excess: string;
      coinsurance: {
        vet_fee_excess: string;
        chosen_additional_excess: string;
        age_8_excess: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      excess {
        fixed_excess
        coinsurance {
          vet_fee_excess
          chosen_additional_excess
          age_8_excess
        }
      }
    }
  }
`;

const useExcessSection = (): Detail[][] => {
  const {
    csPetCheckYourDetailsDetailsSection: {
      excess: {
        fixed_excess,
        coinsurance: { vet_fee_excess, chosen_additional_excess, age_8_excess },
      },
    },
  } = useStaticQuery<CsExcessSectionProps>(query);
  const quote = useCurrentQuote();
  const petInfos = quote?.petInfos ?? undefined;
  if (petInfos === undefined) {
    return [];
  }

  return isCoinsuranceQuote
    ? petInfos.map((petInfo): Detail[] => [
        createDetail(
          vet_fee_excess,
          formatStringInPoundsWithSign(petInfo.voluntaryExcessAmount)
        ),
        createDetail(
          isPet8YearsOrOlderOnDate(
            petInfo.dob,
            getCoverStartDate(quote.policyInfo?.coverStartDate)
          )
            ? age_8_excess
            : chosen_additional_excess,
          `${parseInt(petInfo.voluntaryExcessPercentage, 10)}%`
        ),
      ])
    : petInfos.map((petInfo): Detail[] => [
        createDetail(
          petNameReplacer(petInfo.petName, fixed_excess),
          formatStringInPoundsWithSign(petInfo.voluntaryExcessAmount)
        ),
      ]);
};

export default useExcessSection;
