import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { PanelOptions } from '@rsa-digital/evo-shared-components/components/Panel/PredefinedPanel/predefinedStyles';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import { PageTitle } from 'helpers/eventTracking';
import {
  BooleanInputWrapper,
  FlexibleWidthDividerWithMargin,
  GridWithVerticalMargin,
  LargeTextLabelWithMargin,
  QuestionPanel,
  RichTextWithTopMargin,
  StyledPredefinedPanel,
} from './styles';

type DeclarationProps = {
  id: string;
  hasAgreed: boolean | undefined;
  updateHasAgreed: (update: boolean) => void;
  error?: string;
  questionId: string;
  questionText: string;
  disclaimerText?: string;
  pageTitle: PageTitle;
} & ComponentProps;

const Declaration: React.FC<DeclarationProps> = ({
  id,
  hasAgreed,
  updateHasAgreed,
  error,
  questionId,
  questionText,
  disclaimerText,
  pageTitle,
  children,
  ...rest
}) => (
  <StyledPredefinedPanel
    borderShadows={{ displayOutset: true }}
    panelType={PanelOptions.NONE}
    {...componentProps(rest)}
    id={id}>
    {children}
    {!error && <FlexibleWidthDividerWithMargin />}
    <QuestionPanel
      id={`${questionId}-container`}
      panelType={error ? PanelOptions.ERROR : PanelOptions.NONE}
      cornerLabel={{
        icon: error ? 'error' : undefined,
      }}
      bottomLabelId={`${questionId}-error`}
      bottomLabelText={error}
      bottomLabelRole="alert"
      bottomLabelAriaLive="assertive">
      <GridWithVerticalMargin alignLeft error={!!error}>
        <GridItem desktop={9} tabletLandscape={9}>
          <LargeTextLabelWithMargin id={`${questionId}-label`} htmlFor={questionId}>
            {questionText}
          </LargeTextLabelWithMargin>
        </GridItem>
        <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
          <BooleanInputWrapper>
            <BooleanRadioInput
              id={questionId}
              value={hasAgreed}
              onChange={updateHasAgreed}
              aria-describedby={`${questionId}-error`}
            />
          </BooleanInputWrapper>
        </GridItem>
        {disclaimerText && (
          <GridItem desktop={9} tabletLandscape={9}>
            <RichTextWithTopMargin
              data-cy={`${id}-disclaimer-text`}
              html={disclaimerText}
              pageTitle={pageTitle}
            />
          </GridItem>
        )}
      </GridWithVerticalMargin>
    </QuestionPanel>
  </StyledPredefinedPanel>
);

export default Declaration;
