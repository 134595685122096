import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { getCoverStartDate } from 'businessLogic/getCoverStartDate';
import { isPet8YearsOrOlderOnDate } from 'businessLogic/petAge';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { trimEnd } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import CoverStartDate from 'components/CoverStartDate';
import { FlexibleWidthDividerWithMargin } from 'components/Declaration/styles';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import JointPolicyholderForm from 'forms/JointPolicyholderForm';
import { Heading } from 'forms/SectionHeading/styles';
import { areDateValuesEqual } from 'helpers/dateHelpers';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { isCoinsuranceQuote } from 'helpers/productHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { CurrentQuote, useCurrentQuote } from 'helpers/useCurrentQuote';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { useJointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { initialDateValue } from 'state/formData/shared/dateValue';
import { CsAdditionalFormSectionBanner } from 'types/contentStack';
import DetailGroupSection from './DetailGroupSection';
import {
  BoldParagraphLargeSpan,
  DetailGroupSectionContainer,
  LeftPositionedQuoteReferenceWrapper,
  ParagraphLargeSpan,
  StyledAdditionalFormSectionBanner,
} from './DetailGroupSection/styles';
import useAboutYouSection from './useAboutYouSection';
import useAdditionalInformationSection from './useAdditionalInformationSection';
import useCoverLevelSection from './useCoverLevelSection';
import useExcessSections from './useExcessSections';
import usePetSections from './usePetSections';
import useQuoteReference from './useQuoteReference';
import CheckContactDetailsForm from '../../../forms/CheckContactDetailsForm';

type DetailsSectionProps = {
  currentQuote: CurrentQuote;
  getError: FieldFunction<FormDetails, string | undefined>;
  showValidation: FieldFunction<FormDetails, void>;
};

type DetailsSectionCsProps = {
  csPetCheckYourDetailsDetailsSection: {
    about_you: {
      about_you_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    about_your_joint_policyholder: {
      about_your_joint_policyholder_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    about_your_pet: {
      about_your_pet_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    excess: {
      excess_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
      coinsurance: {
        section_description: string;
        under_8_disclaimer: string;
      };
    };
    your_cover_dates: {
      your_cover_dates_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    your_cover_level: {
      your_cover_level_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
    additional_information: {
      additional_information_heading: string;
      edit_button_text: string;
      edit_button_screenreader_text: string;
    };
  };
  csPetAboutYouAndYourPet: {
    joint_policyholder_banner: CsAdditionalFormSectionBanner;
  };
};

const query = graphql`
  query {
    csPetCheckYourDetailsDetailsSection {
      about_you {
        about_you_heading
        edit_button_text
        edit_button_screenreader_text
      }
      about_your_joint_policyholder {
        about_your_joint_policyholder_heading
        edit_button_text
        edit_button_screenreader_text
      }
      about_your_pet {
        about_your_pet_heading
        edit_button_text
        edit_button_screenreader_text
      }
      excess {
        excess_heading
        edit_button_text
        edit_button_screenreader_text
        coinsurance {
          section_description
          under_8_disclaimer
        }
      }
      your_cover_dates {
        your_cover_dates_heading
        edit_button_text
        edit_button_screenreader_text
      }
      your_cover_level {
        your_cover_level_heading
        edit_button_text
        edit_button_screenreader_text
      }
      additional_information {
        additional_information_heading
        edit_button_text
        edit_button_screenreader_text
      }
    }
    csPetAboutYouAndYourPet {
      joint_policyholder_banner {
        heading_text
        body_text
        button_text
        button_aria_label
      }
    }
  }
`;

const DetailsSection: React.FC<DetailsSectionProps> = ({
  currentQuote,
  getError,
  showValidation,
}) => {
  const {
    csPetCheckYourDetailsDetailsSection: {
      about_you,
      about_your_pet,
      excess,
      your_cover_dates,
      your_cover_level,
      additional_information,
    },
    csPetAboutYouAndYourPet: { joint_policyholder_banner },
  } = useStaticQuery<DetailsSectionCsProps>(query);

  const yourCoverDatesSectionId = 'your-cover-dates-section';
  const yourCoverLevelSectionId = 'your-cover-level-section';
  const aboutYouSectionId = 'about-you-section';
  const yourContactDeatilsSectionId = 'your-contact-details-section';
  const aboutYourJointPolicyholderSectionId = 'about-your-joint-policyholder-section';
  const aboutYourPetSectionId = 'about-your-pet-section';
  const excessSectionId = 'excess-section';
  const additionalInformationSectionId = 'additional-information-section';
  const [customerDetails] = useCustomerDetails();
  const [
    jointPolicyholderDetails,
    updateJointPolicyholderDetails,
  ] = useJointPolicyholderDetails();

  const petsDetails = usePetSections();
  const petsExcess = useExcessSections();
  const isCoinsurance = isCoinsuranceQuote;
  const quote = useCurrentQuote();
  const aboutYouSectionDetails = useAboutYouSection();
  const quoteReference = useQuoteReference();
  const [displayJointPolicyholderForm, setDisplayJointPolicyholderForm] = useState(
    jointPolicyholderDetails.includeJointPolicyholder
  );
  // warning panel is displayed when the customer enters their own details in the joint policyholder form
  const displayJointPolicyholderWarningPanel =
    customerDetails.customerFirstName !== '' &&
    customerDetails.customerFirstName ===
      jointPolicyholderDetails.jointPolicyholderFirstName &&
    customerDetails.customerLastName !== '' &&
    customerDetails.customerLastName ===
      jointPolicyholderDetails.jointPolicyholderLastName &&
    !areDateValuesEqual(
      addLeadingZerosToDateValue(customerDetails.customerDob),
      initialDateValue
    ) &&
    areDateValuesEqual(
      addLeadingZerosToDateValue(customerDetails.customerDob),
      jointPolicyholderDetails.jointPolicyholderDob
    );

  useEffect(() => {
    if (jointPolicyholderDetails.includeJointPolicyholder) {
      setDisplayJointPolicyholderForm(true);
    }
  }, [jointPolicyholderDetails]);

  return (
    <>
      <DetailGroupSectionContainer id={yourCoverDatesSectionId}>
        {quoteReference && (
          <LeftPositionedQuoteReferenceWrapper>
            <ParagraphLargeSpan>{quoteReference.label} </ParagraphLargeSpan>
            <BoldParagraphLargeSpan>{quoteReference.value}</BoldParagraphLargeSpan>
          </LeftPositionedQuoteReferenceWrapper>
        )}
        <Heading>{your_cover_dates.your_cover_dates_heading}</Heading>
        <FlexibleWidthDividerWithMargin aria-hidden />
        <CoverStartDate pageTitle={PageTitle.CheckYourDetails} />
      </DetailGroupSectionContainer>
      <DetailGroupSection
        sectionHeading={your_cover_level.your_cover_level_heading}
        sectionId={yourCoverLevelSectionId}
        details={useCoverLevelSection()}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit cover level');
          navigate(`${quoteAndBuyRoutes.quoteSummary}`);
        }}
        editButtonLabel={your_cover_level.edit_button_text}
        editButtonScreenreaderText={your_cover_level.edit_button_screenreader_text}
      />
      {currentQuote?.petInfos?.map((petInfo, index) => (
        // Index is the unique identifier for pet info
        // eslint-disable-next-line react/no-array-index-key
        <Fragment key={index}>
          <DetailGroupSection
            sectionHeading={petNameReplacer(
              trimEnd(petInfo.petName),
              excess.excess_heading
            )}
            sectionId={`${excessSectionId}[${index}]`}
            details={petsExcess[index]}
            editButtonOnClick={() => {
              trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit pet excess');
              navigate(`${quoteAndBuyRoutes.quoteSummary}#excess-section`);
            }}
            editButtonLabel={excess.edit_button_text}
            editButtonScreenreaderText={excess.edit_button_screenreader_text}
            sectionDescription={
              isCoinsurance ? excess.coinsurance.section_description : undefined
            }
            sectionDisclaimer={
              isCoinsurance &&
              !isPet8YearsOrOlderOnDate(
                petInfo.dob,
                getCoverStartDate(quote.policyInfo?.coverStartDate)
              )
                ? excess.coinsurance.under_8_disclaimer
                : undefined
            }
          />
          <DetailGroupSection
            sectionHeading={petNameReplacer(
              trimEnd(petInfo.petName),
              about_your_pet.about_your_pet_heading
            )}
            sectionId={`${aboutYourPetSectionId}[${index}]`}
            details={petsDetails[index]}
            editButtonOnClick={() => {
              trackTextButtonClick(PageTitle.CheckYourDetails, 'edit pet details');
              navigate(`${quoteAndBuyRoutes.aboutYouAndYourPet}#petDetails[${index}]`);
            }}
            editButtonLabel={about_your_pet.edit_button_text}
            editButtonScreenreaderText={about_your_pet.edit_button_screenreader_text}
          />
        </Fragment>
      ))}
      <DetailGroupSection
        sectionHeading={additional_information.additional_information_heading}
        sectionId={additionalInformationSectionId}
        details={useAdditionalInformationSection()}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit additional information');
          navigate(
            `${quoteAndBuyRoutes.aboutYouAndYourPet}#numberOfPetsInHousehold-container`
          );
        }}
        editButtonLabel={additional_information.edit_button_text}
        editButtonScreenreaderText={additional_information.edit_button_screenreader_text}
      />
      <DetailGroupSection
        sectionHeading={about_you.about_you_heading}
        sectionId={aboutYouSectionId}
        details={aboutYouSectionDetails}
        editButtonOnClick={() => {
          trackTextButtonClick(PageTitle.CheckYourDetails, 'Edit about you');
          navigate(`${quoteAndBuyRoutes.aboutYouAndYourPet}#about-you-section`);
        }}
        editButtonLabel={about_you.edit_button_text}
        editButtonScreenreaderText={about_you.edit_button_screenreader_text}
        piiOptions={{ detailValuesArePii: true }}
      />
      <CheckContactDetailsForm
        getError={getError}
        showValidation={showValidation}
        sectionId={yourContactDeatilsSectionId}
      />
      <DetailGroupSectionContainer id={aboutYourJointPolicyholderSectionId}>
        <Heading>{joint_policyholder_banner.heading_text}</Heading>
        <FlexibleWidthDividerWithMargin aria-hidden />
        {displayJointPolicyholderForm ? (
          <>
            <JointPolicyholderForm
              removeJointPolicyholderButtonOnClick={() =>
                setDisplayJointPolicyholderForm(false)
              }
              formValidation={{ getError, showValidation }}
              displayWarningPanel={displayJointPolicyholderWarningPanel}
            />
          </>
        ) : (
          <>
            <StyledAdditionalFormSectionBanner
              data-cy="addJointPolicyholderBanner"
              headingText=""
              bodyText={joint_policyholder_banner.body_text}
              buttonText={joint_policyholder_banner.button_text}
              buttonAriaLabel={joint_policyholder_banner.button_aria_label}
              buttonId="addJointPolicyholderButton"
              addFormSectionButtonClick={() => {
                updateJointPolicyholderDetails({ includeJointPolicyholder: true });
                setDisplayJointPolicyholderForm(true);
                trackTextButtonClick(
                  PageTitle.AboutYouAndYourPet,
                  'Add joint policy holder'
                );
              }}
            />
          </>
        )}
      </DetailGroupSectionContainer>
    </>
  );
};

export default DetailsSection;
